<template>
    <div class="about-us-all">
		<!-- <div class="common-title certification-title">Certification</div> -->
		<img src="@/assets/images/certification/1.png" alt="" class="certify-img"/>
		<img src="@/assets/images/certification/2.png" alt="" class="certify-img"/>
	</div>
</template>

<script>
	import '@/assets/less/AboutUs.less';
	export default {
		name: 'Certification',
		components: {},
		data() {
			return {}
		}
	}
</script>